"use client"
import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import { getCookie } from 'cookies-next';
import { useState } from "react";
import toast from "react-hot-toast";
import axiosRetry from 'axios-retry';

function useHttp(useV2 = false) {
    const [loading, setLoading] = useState(true);

    const http = axios.create({
        baseURL: useV2 ? process.env.NEXT_PUBLIC_BACKEND_URL_V2 : process.env.NEXT_PUBLIC_BACKEND_URL,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            // 'X-Requested-With': 'XMLHttpRequest',
        },
        withCredentials: true,
    });

    axiosRetry(http, { retries: 3, retryDelay: axiosRetry.linearDelay() })


    const parseObjToQueryParams = (obj, prefix) => {
        let str = [];
        let item;

        for (item in obj) {
            if (obj.hasOwnProperty(item)) {
                var name = prefix ? prefix + "[" + item + "]" : item,
                    value = obj[item];
                str.push((value !== null && typeof value === "object") ?
                    parseObjToQueryParams(value, name) :
                    encodeURIComponent(name) + "=" + encodeURIComponent(value));
            }
        }

        return str.join("&");
    };

    const post = async (path, data, forceFormData, showToast = true) => {
        try {
            setLoading(true);

            let response = await http.post(path, data, {
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": forceFormData ? "multipart/form-data" : "application/json"
                }
            });

            if (response.data.message) {
                toast.success(response.data.message);
            }
            if (response.data.redirect) {
                setTimeout(() => {
                    window.location.href = response.data.redirect;
                }, 600);
            }

            return response.data;
        } catch (errorResponse) {
            if (showToast) {
                if (errorResponse?.status === 401) {
                    return null
                }
                else if (errorResponse?.response && errorResponse?.response.data.message) {
                    toast.error(errorResponse.response.data.message);
                    return "error";
                }
                else {
                    const unknownErrorMessage = "Ocorreu um erro desconhecido!";
                    toast(unknownErrorMessage);

                    // Capture the error in Sentry with custom information
                    Sentry.captureException(new Error(unknownErrorMessage), {
                        extra: {
                            path,
                            data,
                            errorResponse,
                            completeUrl: `${process.env.NEXT_PUBLIC_BACKEND_URL}${path}`
                        }
                    });
                }
            }
        } finally {
            setLoading(false);
        }
        return null;
    }

    const get = async (path, data = {}, showToast = true) => {
        try {
            setLoading(true);
            let response = await http.get(`${path}${Object.keys(data).length > 0 ? "?" + parseObjToQueryParams(data) : ""}`, {
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                }
            });

            if (response.data.message) {
                toast.success(response.data.message);
            }

            return response.data;
        } catch (errorResponse) {
            if (showToast) {
                if (errorResponse?.response && errorResponse?.response.data.message) {
                    toast.error(errorResponse.response.data.message);
                } else {
                    const unknownErrorMessage = "Ocorreu um erro desconhecido!";
                    toast(unknownErrorMessage);

                    // Capture the error in Sentry with custom information
                    Sentry.captureException(new Error(unknownErrorMessage), {
                        extra: {
                            path,
                            data,
                            errorResponse,
                            completeUrl: `${process.env.NEXT_PUBLIC_BACKEND_URL}${path}${Object.keys(data).length > 0 ? "?" + parseObjToQueryParams(data) : ""}`
                        }
                    });
                }
            }

            return null;
        } finally {
            setLoading(false);
        }
    }

    return { post, get, loading };
}

export default useHttp;
