"use client"
import { useConfig } from '@/contexts/config-context'
import { Icon } from '@iconify/react'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'


export default function Footer() {
    let configData = useConfig();
    try {
        if (typeof configData == 'string') {
            configData = JSON.parse(configData);
        }
    } catch (e) {
        console.error("Erro ao fazer parse do JSON", e)
    }

    const redirectToPage = (url) => window.open(url, '_blank');

    if (!configData?.facebook && !configData?.instagram && !configData?.tiktok) {
        return <></>
    }

    return (
        <Box sx={{
            textAlign: "center",
            mt: 2,
        }}>
            <Typography variant='body2'>Acompanhe nossas redes sociais</Typography>
            <Box display="flex" justifyContent={"center"} gap={2} mt={1}>
                {
                    configData?.facebook && <IconButton onClick={() => redirectToPage(configData.facebook)}><Icon style={{ fontSize: 30 }} icon="devicon:facebook" /></IconButton>
                }
                {
                    configData?.instagram && <IconButton onClick={() => redirectToPage(configData.instagram)}><Icon style={{ fontSize: 30 }} icon="skill-icons:instagram" /></IconButton>
                }
                {
                    configData?.tiktok && <IconButton onClick={() => redirectToPage(configData.tiktok)}><Icon style={{ fontSize: 30 }} icon="ic:baseline-tiktok" /></IconButton>
                }
            </Box>
        </Box>
    )
}
